import { createStore } from "vuex";
import notifications from "@/store/modules/notificaations";
import oldModule from "@/store/modules/needSeparate";
import states from "@/store/modules/states";
import blog from "@/store/modules/blogStore";
import companies from "@/store/modules/companiesStore";
import calendar from "@/store/modules/calendarStore";
import cart from "@/store/modules/cartStore";
import newsletters from "@/store/modules/newslettersStore";
import createUser from "@/store/modules/createUser";

export default createStore({
  modules: {
    oldModule: oldModule,
    states: states,
    notifications: notifications,
    blog: blog,
    companies: companies,
    calendar: calendar,
    cart: cart,
    newsletters: newsletters,
    createUser: createUser,
  },
});
