const oldModule = {
  state: {
    expertServices: [],
    appointment_id: null,
    changePasswordToken: null,
    expertServicesValue: [],
    accountHaveChanges: null,
    upcomingAnimation: true,
    mail: "",
    currentUserInformation: {},
    upcomingAppointment: {},
    appointments: [],
    calendarSelected: [],
    chatActive: "",
    selectHour: [],
    cartItems: [],
    bookData: {
      language: null,
      services: null,
      expert: null,
      type: null,
    },
    serviceCreateDescriptions: {
      shortDescriptionValue: {
        english: "",
        french: "",
      },
      fullDescriptionValue: {
        english: "",
        french: "",
      },
      englishServiceName: "",
      frenchServiceName: "",
    },
    serviceEditDescriptions: {
      shortDescriptionValue: {
        english: "",
        french: "",
      },
      fullDescriptionValue: {
        english: "",
        french: "",
      },
      englishServiceName: "",
      frenchServiceName: "",
    },
    dragAndDropImage: {},
    companyLogoPopup: false,
    companyThumbnail: "",
    information: "",
    changesPopup: {
      show: false,
      to: "",
      save: null,
      leave: null,
    },
    view: {
      cart: false,
      table: true,
    },
    schedulesWorkingHours: {},
    calendarDaySelect: new Date(),
  },
  mutations: {
    SET_APPOINTMENT_ID: (state, payload) => {
      state.appointment_id = payload;
    },
    SET_EXPERT_SERVICES: (state, payload) => {
      state.expertServices = payload;
    },
    SET_EXPERT_SERVICES_VALUE: (state, payload) => {
      state.expertServicesValue = payload;
    },
    SET_CHANGE_PASSWORD_TOKEN: (state, payload) => {
      state.changePasswordToken = payload;
    },
    SET_ACCOUNT_CHANGES: (state, payload) => {
      state.accountHaveChanges = payload;
    },
    SET_UPCOMING_ANIMATION: (state, payload) => {
      state.upcomingAnimation = payload;
    },
    SET_CALENDAR_DAY_SELECT: (state, payload) => {
      state.calendarDaySelect = payload;
    },
    SET_CURRENT_USER_INFORMATION: (state, payload) => {
      state.currentUserInformation = payload;
    },
    SET_APPOINTMENTS: (state, payload) => {
      state.appointments = payload;
    },
    SET_BOOK_DATA: (state, payload) => {
      state.bookData = payload;
    },
    SET_BOOK_DATA_EXPERT: (state, payload) => {
      state.bookData.expert = payload;
    },
    SET_BOOK_DATA_SERVICES: (state, payload) => {
      state.bookData.services = payload;
    },
    SET_HOUR: (state, payload) => {
      state.selectHour = payload;
    },
    SET_CART_ITEMS: (state, payload) => {
      state.cartItems = payload;
    },
    SET_CALENDAR_SELECT: (state, payload) => {
      state.calendarSelected = payload;
    },
    SET_CALENDAR_SELECT_TYPE: (state, payload) => {
      state.calendarSelected.type = payload;
    },
    SET_CALENDAR_SELECT_SERVICE: (state, payload) => {
      state.calendarSelected.services = payload;
    },
    SET_CALENDAR_SELECT_LANGUAGE: (state, payload) => {
      state.calendarSelected.language = payload;
    },
    SET_CALENDAR_SELECT_EXPERT: (state, payload) => {
      state.calendarSelected.expert = payload;
    },
    SET_CHAT: (state, payload) => {
      state.chatActive = payload;
    },
    SET_UPCOMING_APPOINTMENT: (state, payload) => {
      state.upcomingAppointment = payload;
    },
    SET_SERVICES_CREATE_DESCRIPTIONS: (state, payload) => {
      state.serviceCreateDescriptions = payload;
    },
    SET_SERVICES_EDIT_DESCRIPTIONS: (state, payload) => {
      state.serviceEditDescriptions = payload;
    },
    SET_DRAG_AND_DROP: (state, payload) => {
      state.dragAndDropImage = payload;
    },

    SET_EDIT_USER_INFORMATION: (state, payload) => {
      state.information = payload;
    },
    SET_STATE_POPUP: (state, payload) => {
      state.changesPopup = payload;
    },
    SET_VIEW: (state, payload) => {
      state.view = payload;
    },
    SET_LOGO_POPUP: (state, payload) => {
      state.companyLogoPopup = payload;
    },
    SET_THUMBNAIL: (state, payload) => {
      state.companyThumbnail = payload;
    },
    SET_SCHEDULES_WORKING_HOURS: (state, payload) => {
      state.schedulesWorkingHours = payload;
    },
    SET_MAIL: (state, payload) => {
      state.mail = payload;
    },
  },
  actions: {},
  modules: {},
  getters: {
    APPOINTMENT_ID: (state) => {
      return state.appointment_id;
    },
    EXPERT_SERVICES: (state) => {
      return state.expertServices;
    },
    EXPERT_SERVICES_VALUE: (state) => {
      return state.expertServicesValue;
    },
    CHANGE_PASSWORD_TOKEN: (state) => {
      return state.changePasswordToken;
    },
    ACCOUNT_CHANGES: (state) => {
      return state.accountHaveChanges;
    },
    UPCOMING_ANIMATION: (state) => {
      return state.upcomingAnimation;
    },
    CALENDAR_DAY_SELECT: (state) => {
      return state.calendarDaySelect;
    },
    CURRENT_USER_INFORMATION: (state) => {
      return state.currentUserInformation;
    },
    APPOINTMENTS: (state) => {
      return state.appointments;
    },
    HOUR: (state) => {
      return state.selectHour;
    },
    CART_ITEMS: (state) => {
      return state.cartItems;
    },
    BOOK_DATA: (state) => {
      return state.bookData;
    },
    CALENDAR_SELECT: (state) => {
      return state.calendarSelected;
    },
    CHAT: (state) => {
      return state.chatActive;
    },
    UPCOMING_APPOINTMENT: (state) => {
      return state.upcomingAppointment;
    },
    SERVICES_CREATE_DESCRIPTIONS: (state) => {
      return state.serviceCreateDescriptions;
    },
    SERVICES_EDIT_DESCRIPTIONS: (state) => {
      return state.serviceEditDescriptions;
    },
    DRAG_AND_DROP: (state) => {
      return state.dragAndDropImage;
    },
    EDIT_USER_INFORMATION: (state) => {
      return state.information;
    },
    STATE_POPUP: (state) => {
      return state.changesPopup;
    },
    VIEW: (state) => {
      return state.view;
    },
    COMPANY_LOGO_POPUP: (state) => {
      return state.companyLogoPopup;
    },
    THUMBNAIL: (state) => {
      return state.companyThumbnail;
    },
    SCHEDULES_WORKING_HOURS: (state) => {
      return state.schedulesWorkingHours;
    },
    MAIL: (state) => {
      return state.mail;
    },
  },
};

export default oldModule;
