const newsletters = {
  state: {
    newsletters: null,
  },
  mutations: {
    SET_NEWSLETTERS: (state, payload) => {
      state.newsletters = payload;
    },
  },
  actions: {},
  modules: {},
  getters: {
    NEWSLETTERS: (state) => {
      return state.newsletters;
    },
  },
};

export default newsletters;
