const createUser = {
  state: {
    userCreateRole: null,
    newUserCredentials: null,
    showCredentialsPopup: false,
  },
  mutations: {
    SET_USER_ROLE_CREATE: (state, payload) => {
      state.userCreateRole = payload;
    },
    SET_NEW_USER_CREDENTIALS: (state, payload) => {
      state.newUserCredentials = payload;
    },
    SET_CREDENTIALS_POPUP: (state, payload) => {
      state.showCredentialsPopup = payload;
    },
  },
  actions: {},
  modules: {},
  getters: {
    USER_ROLE_CREATE: (state) => {
      return state.userCreateRole;
    },
    NEW_USER_CREDENTIALS: (state) => {
      return state.newUserCredentials;
    },
    CREDENTIALS_POPUP: (state) => {
      return state.showCredentialsPopup;
    },
  },
};

export default createUser;
