const cart = {
  state: {
    bookedStatus: {},
    credits: null,
    creditUsed: null,
    totalCost: null,
    totalCostAfterUsedCredit: null,
  },
  mutations: {
    SET_BOOKED_STATUS: (state, payload) => {
      state.bookedStatus = payload;
    },
    SET_CREDITS: (state, payload) => {
      state.credits = payload;
    },
    SET_CREDIT_USED: (state, payload) => {
      state.creditUsed = payload;
    },
    SET_TOTAL_COST: (state, payload) => {
      state.totalCost = payload;
    },
    SET_TOTAL_COST_AFTER_USED_CREDIT: (state, payload) => {
      state.totalCostAfterUsedCredit = payload;
    },
  },
  actions: {},
  modules: {},
  getters: {
    BOOKED_STATUS: (state) => {
      return state.bookedStatus;
    },
    CREDITS: (state) => {
      return state.credits;
    },
    CREDIT_USED: (state) => {
      return state.creditUsed;
    },
    TOTAL_COST: (state) => {
      return state.totalCost;
    },
    TOTAL_COST_AFTER_USED_CREDIT: (state) => {
      return state.totalCostAfterUsedCredit;
    },
  },
};

export default cart;
