<template>
  <div class="overflow-x-hidden">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  destroyed() {
    this.sockets.unsubscribe("receive-message");
    this.sockets.unsubscribe("receive-chats");
    this.sockets.unsubscribe("cart-expired");
  },
};
</script>
<style></style>
