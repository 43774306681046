const companies = {
  state: {
    companiesItems: null,
    companiesView: {
      cart: false,
      table: true,
    },
    companyEmployees: [],
  },
  mutations: {
    SET_COMPANIES_ITEMS: (state, payload) => {
      state.companiesItems = payload;
    },
    SET_COMPANIES_VIEW: (state, payload) => {
      state.companiesView = payload;
    },
    SET_COMPANY_EMPLOYEES: (state, payload) => {
      state.companyEmployees = payload;
    },
  },
  actions: {},
  modules: {},
  getters: {
    COMPANIES_ITEMS: (state) => {
      return state.companiesItems;
    },
    COMPANIES_VIEW: (state) => {
      return state.companiesView;
    },
    COMPANY_EMPLOYEES: (state) => {
      return state.companyEmployees;
    },
  },
};

export default companies;
