const blog = {
  state: {
    blogItems: null,
    blogLatestPost: null,
    blogTags: [],
  },
  mutations: {
    SET_BLOG_ITEMS: (state, payload) => {
      state.blogItems = payload;
    },
    SET_BLOG_LATEST_POST: (state, payload) => {
      state.blogLatestPost = payload;
    },
    SET_BLOG_TAGS: (state, payload) => {
      state.blogTags = payload;
    },
  },
  actions: {},
  modules: {},
  getters: {
    BLOG_ITEMS: (state) => {
      return state.blogItems;
    },
    BLOG_LATEST_POST: (state) => {
      return state.blogLatestPost;
    },
    BLOG_TAGS: (state) => {
      return state.blogTags;
    },
  },
};

export default blog;
