import axios from "axios";
import { getCookie } from "@/helpers/cookieHelper";

export function setAxiosConfig() {
  axios.defaults.baseURL = process.env.VUE_APP_ORIGIN_API_PATH;
  axios.defaults.axiosNoAuth = process.env.VUE_APP_ORIGIN_API_PATH;

  axios.defaults.withCredentials = true;

  axios.interceptors.request.use((request) => {
    request.headers.common.Authorization = `Bearer ${getCookie(
      "access_token"
    )}`;
    return request;
  });
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          let token = localStorage.getItem("refresh_token");
          await axios.post("/auth/refresh", { token: token });
          originalConfig.headers.Authorization = `Bearer ${getCookie(
            "access_token"
          )}`;
          return axios(originalConfig);
        } catch (e) {
          window.location.href = "/login";
        }
      }
      throw err;
    }
  );
}
