import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import store from "@/store/store";
import { getCookie } from "@/helpers/cookieHelper";

const HomePage = () =>
  import(
    /* webpackChunkName: "HomePage" */
    "@/pages/home/HomePage"
  );
const LoginPage = () =>
  import(
    /* webpackChunkName: "LoginPage" */
    "@/pages/no-auth/LoginPage"
  );
const ServicesPage = () =>
  import(
    /* webpackChunkName: "ServicesPage" */
    "@/pages/services/ServicesPage"
  );
const ExpertsPage = () =>
  import(
    /* webpackChunkName: "ExpertsPage" */
    "@/pages/experts/ExpertsPage"
  );
const ChatPage = () =>
  import(
    /* webpackChunkName: "ChatPage" */
    "@/pages/chat/ChatPage"
  );
const ExpertSinglePage = () =>
  import(
    /* webpackChunkName: "ExpertSinglePage" */
    "@/pages/experts/ExpertSinglePage"
  );
const CalendarPage = () =>
  import(
    /* webpackChunkName: "CalendarPage" */
    "@/pages/calendar/CalendarPage"
  );
const ExpertDayCalendarPage = () =>
  import(
    /* webpackChunkName: "ExpertDayCalendarPage" */
    "@/pages/calendar/ExpertDayCalendarPage"
  );
const ServiceSinglePage = () =>
  import(
    /* webpackChunkName: "ServiceSinglePage" */
    "@/pages/services/ServiceSinglePage"
  );
const AppointmentPage = () =>
  import(
    /* webpackChunkName: "AppointmentPage" */
    "@/pages/appointment/AppointmentPage"
  );
const AccountPage = () =>
  import(
    /* webpackChunkName: "AccountPage" */
    "@/pages/account/AccountPage"
  );
const ChangePasswordPage = () =>
  import(
    /* webpackChunkName: "ChangePasswordPage" */
    "@/pages/account/AccountChangePasswordPage.vue"
  );
const BlogPages = () =>
  import(
    /* webpackChunkName: "BlogPages" */
    "@/pages/blog/BlogPages"
  );
const BlogSinglePage = () =>
  import(
    /* webpackChunkName: "BlogSinglePage" */
    "@/pages/blog/BlogSinglePage"
  );
const BlogEditPage = () =>
  import(
    /* webpackChunkName: "BlogEditPage" */
    "@/pages/blog/BlogEditPage"
  );
const BlogAddPage = () =>
  import(
    /* webpackChunkName: "BlogAddPage" */
    "@/pages/blog/BlogAddPage"
  );
const AppointmentHistoryPage = () =>
  import(
    /* webpackChunkName: "AppointmentHistoryPage" */
    "@/pages/history/AppointmentHistoryPage"
  );
const CartPage = () =>
  import(
    /* webpackChunkName: "CartPage" */
    "@/pages/cart/CartPage"
  );
const CompaniesPage = () =>
  import(
    /* webpackChunkName: "CompaniesPage" */
    "@/pages/companies/CompaniesPage"
  );
const EmployeesPage = () =>
  import(
    /* webpackChunkName: "HomEmployeesPageePage" */
    "@/pages/employee/EmployeesPage"
  );
const TransactionsPage = () =>
  import(
    /* webpackChunkName: "TransactionsPage" */
    "@/pages/transactions/TransactionsPage"
  );
const TransactionSinglePage = () =>
  import(
    /* webpackChunkName: "TransactionSinglePage" */
    "@/pages/transactions/TransactionSinglePage"
  );
const TransactionCompanySinglePage = () =>
  import(
    /* webpackChunkName: "TransactionCompanySinglePage" */
    "@/pages/transactions/TransactionCompanySinglePage"
  );
const ServicesAddPage = () =>
  import(
    /* webpackChunkName: "ServicesAddPage" */
    "@/pages/services/ServicesAddPage"
  );
const ServicesEditPage = () =>
  import(
    /* webpackChunkName: "ServicesEditPage" */
    "@/pages/services/ServicesEditPage"
  );
const ExpertAddPage = () =>
  import(
    /* webpackChunkName: "ExpertAddPage" */
    "@/pages/experts/ExpertAddPage"
  );
const ExpertEditPage = () =>
  import(
    /* webpackChunkName: "ExpertEditPage" */
    "@/pages/experts/ExpertEditPage"
  );
const CompanyPage = () =>
  import(
    /* webpackChunkName: "CompanyPage" */
    "@/pages/companies/CompanyPage"
  );
const CompanyAddPage = () =>
  import(
    /* webpackChunkName: "CompanyAddPage" */
    "@/pages/companies/CompanyAddPage"
  );
const EmployeesSinglePage = () =>
  import(
    /* webpackChunkName: "EmployeesSinglePage" */
    "@/pages/employee/EmployeesSinglePage"
  );
const NewslettersPage = () =>
  import(
    /* webpackChunkName: "NewslettersPage" */
    "@/pages/newsletter/NewslettersPage"
  );
const RegistrationPage = () =>
  import(
    /* webpackChunkName: "RegistrationPage" */
    "@/pages/no-auth/RegistrationPage"
  );
const ActivationPage = () =>
  import(
    /* webpackChunkName: "ActivationPage" */
    "@/pages/no-auth/ActivationPage"
  );
const StatisticsPage = () =>
  import(
    /* webpackChunkName: "StatisticsPage" */
    "@/pages/statistics/StatisticsPage"
  );
const ForgotPassword = () =>
  import(
    /* webpackChunkName: "ForgotPassword" */
    "@/pages/no-auth/ForgotPassword"
  );
const ExpertSchedulesDayPage = () =>
  import(
    /* webpackChunkName: "ExpertSchedulesDayPage" */
    "@/pages/calendar/ExpertSchedulesDayPage"
  );
const AppointmentCallPage = () =>
  import(
    /* webpackChunkName: "AppointmentCallPage" */
    "@/pages/appointment/AppointmentCallPage"
  );
const SupportPage = () =>
  import(
    /* webpackChunkName: "SupportPage" */
    "@/pages/support/SupportPage"
  );
const AppointmentOverviewPage = () =>
  import(
    /* webpackChunkName: "AppointmentOverviewPage" */
    "@/pages/history/AppointmentOverviewPage"
  );
const EmployeeAddPage = () =>
  import(
    /* webpackChunkName: "EmployeeAddPage" */
    "@/pages/employee/EmployeeAddPage"
  );
const AfterBookPage = () =>
  import(
    /* webpackChunkName: "AfterBookPage" */
    "@/pages/cart/AfterBookPage"
  );
const NotFound = () =>
  import(
    /* webpackChunkName: "AfterBookPage" */
    "@/pages/NotFound"
  );

const routes = [
  {
    path: "/",
    name: "HomePage",
    meta: {
      auth: true,
      allowUserIds: [1, 2, 3, 4],
      title: {
        en: "Home | Aura Impact Platform",
        fr: "Accueil | Plate-forme Aura Impact",
      },
    },
    component: HomePage,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    meta: {
      auth: true,
      allowUserIds: [1, 2, 3, 4],
      title: {
        en: "Page not found | Aura Impact Platform",
        fr: "Page non trouvée | Plate-forme Aura Impact",
      },
    },
    component: NotFound,
  },
  {
    path: "/login",
    name: "LoginPage",
    meta: {
      auth: false,
      title: {
        en: "Login | Aura Impact Platform",
        fr: "Connexion | Plate-forme Aura Impact",
      },
    },
    component: LoginPage,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    meta: {
      auth: false,
      title: {
        en: "Forgot Password | Aura Impact Platform",
        fr: "Mot de passe oublié | Plate-forme Aura Impact",
      },
    },
    component: ForgotPassword,
  },
  {
    path: "/registration",
    name: "RegistrationPage",
    meta: {
      auth: false,
      title: {
        en: "Registration | Aura Impact Platform",
        fr: "Inscription | Plate-forme Aura Impact",
      },
    },
    component: RegistrationPage,
  },
  {
    path: "/activation",
    name: "ActivationPage",
    meta: {
      auth: false,
      title: {
        en: "Activation | Aura Impact Platform",
        fr: "Activation | Plate-forme Aura Impact",
      },
    },
    component: ActivationPage,
  },
  {
    path: "/blog",
    name: "BlogPages",
    meta: {
      auth: true,
      allowUserIds: [1, 2, 4],
      title: {
        en: "Blog | Aura Impact Platform",
        fr: "Blog | Plate-forme Aura Impact",
      },
    },
    component: BlogPages,
  },
  {
    path: "/appointment/:appointment/call",
    name: "AppointmentCallPage",
    meta: {
      auth: true,
      allowUserIds: [2, 4],
      title: {
        en: "Appointment Call | Aura Impact Platform",
        fr: "Appel à rendez-vous | Plate-forme Aura Impact",
      },
    },
    component: AppointmentCallPage,
  },
  {
    path: "/statistics",
    name: "StatisticsPage",
    meta: {
      auth: true,
      allowUserIds: [2, 3],
      title: {
        en: "Statistics | Aura Impact Platform",
        fr: "Statistiques | Plate-forme Aura Impact",
      },
    },
    component: StatisticsPage,
  },
  {
    path: "/cart",
    name: "CartPage",
    meta: {
      auth: true,
      allowUserIds: [4],
      title: {
        en: "Cart | Aura Impact Platform",
        fr: "Panier | Plate-forme Aura Impact",
      },
    },
    component: CartPage,
  },
  {
    path: "/booked-status",
    name: "AfterBookPage",
    meta: {
      auth: true,
      allowUserIds: [4],
      title: {
        en: "Book result | Aura Impact Platform",
        fr: "Résultat du livre | Plate-forme Aura Impact",
      },
    },
    component: AfterBookPage,
  },
  {
    path: "/employees",
    name: "EmployeesPage",
    meta: {
      auth: true,
      allowUserIds: [1, 3],
      title: {
        en: "Employees | Aura Impact Platform",
        fr: "Employés | Plate-forme Aura Impact",
      },
    },
    component: EmployeesPage,
  },
  {
    path: "/employee/new",
    name: "EmployeeAddPage",
    meta: {
      auth: true,
      allowUserIds: [1],
      title: {
        en: "Create Employee | Aura Impact Platform",
        fr: "Créer un employé | Plate-forme Aura Impact",
      },
    },
    component: EmployeeAddPage,
  },
  {
    path: "/employee/:employee",
    name: "EmployeesSinglePage",
    meta: {
      auth: true,
      allowUserIds: [1, 3],
      title: {
        en: "Login | Aura Impact Platform",
        fr: "Login | Plate-forme Aura Impact",
      },
    },
    component: EmployeesSinglePage,
  },
  {
    path: "/transactions",
    name: "TransactionsPage",
    meta: {
      auth: true,
      allowUserIds: [1, 3, 4],
      title: {
        en: "Transactions | Aura Impact Platform",
        fr: "Transactions | Plate-forme Aura Impact",
      },
    },
    component: TransactionsPage,
  },
  {
    path: "/company-transaction/:transaction",
    name: "TransactionCompanySinglePage",
    meta: {
      auth: true,
      allowUserIds: [1],
      title: {
        en: "Company Transaction | Aura Impact Platform",
        fr: "Transaction de l'entreprise | Plate-forme Aura Impact",
      },
    },
    component: TransactionCompanySinglePage,
  },
  {
    path: "/transaction/:transaction",
    name: "TransactionSinglePage",
    meta: {
      auth: true,
      allowUserIds: [1, 3, 4],
      title: {
        en: "Transaction | Aura Impact Platform",
        fr: "Transaction | Plate-forme Aura Impact",
      },
    },
    component: TransactionSinglePage,
  },
  {
    path: "/history",
    name: "AppointmentHistoryPage",
    meta: {
      auth: true,
      allowUserIds: [2, 3, 4],
      title: {
        en: "Appointment History | Aura Impact Platform",
        fr: "Historique des rendez-vous | Plate-forme Aura Impact",
      },
    },
    component: AppointmentHistoryPage,
  },
  {
    path: "/history/:appointment",
    name: "AppointmentOverviewPage",
    meta: {
      auth: true,
      allowUserIds: [2, 3, 4],
      title: {
        en: "Appointment Overview | Aura Impact Platform",
        fr: "Aperçu des nominations | Plate-forme Aura Impact",
      },
    },
    component: AppointmentOverviewPage,
  },
  {
    path: "/blog/:blog",
    name: "BlogSinglePage",
    meta: {
      auth: true,
      allowUserIds: [1, 2, 4],
      title: {
        en: "Blog Post | Aura Impact Platform",
        fr: "Blog Post | Plate-forme Aura Impact",
      },
    },
    component: BlogSinglePage,
  },
  {
    path: "/blog/edit/:blog",
    name: "BlogEditPage",
    meta: {
      auth: true,
      allowUserIds: [1],
      title: {
        en: "Edit blog Post | Aura Impact Platform",
        fr: "Modifier un article de blog | Plate-forme Aura Impact",
      },
    },
    component: BlogEditPage,
  },
  {
    path: "/blog/new",
    name: "BlogAddPage",
    meta: {
      auth: true,
      allowUserIds: [1],
      title: {
        en: "Add blog Post | Aura Impact Platform",
        fr: "Ajouter un article de blog | Plate-forme Aura Impact",
      },
    },
    component: BlogAddPage,
  },
  {
    path: "/newsletters",
    name: "NewslettersPage",
    meta: {
      auth: true,
      allowUserIds: [1],
      title: {
        en: "Newsletters | Aura Impact Platform",
        fr: "Bulletins d'information | Plate-forme Aura Impact",
      },
    },
    component: NewslettersPage,
  },
  {
    path: "/services",
    name: "ServicesPage",
    meta: {
      auth: true,
      allowUserIds: [1, 4],
      title: {
        en: "Services | Aura Impact Platform",
        fr: "Services | Plate-forme Aura Impact",
      },
    },
    component: ServicesPage,
  },
  {
    path: "/service/:service",
    name: "ServiceSinglePage",
    meta: {
      auth: true,
      allowUserIds: [1, 4],
      title: {
        en: "Service | Aura Impact Platform",
        fr: "Service | Plate-forme Aura Impact",
      },
    },
    component: ServiceSinglePage,
  },
  {
    path: "/service/new",
    name: "ServicesAddPage",
    meta: {
      auth: true,
      allowUserIds: [1],
      title: {
        en: "Create Service | Aura Impact Platform",
        fr: "Créer un service | Plate-forme Aura Impact",
      },
    },
    component: ServicesAddPage,
  },
  {
    path: "/service/edit/:service",
    name: "ServicesEditPage",
    meta: {
      auth: true,
      allowUserIds: [1],
      title: {
        en: "Edit Service | Aura Impact Platform",
        fr: "Service d'édition | Plate-forme Aura Impact",
      },
    },
    component: ServicesEditPage,
  },
  {
    path: "/experts",
    name: "ExpertsPage",
    meta: {
      auth: true,
      allowUserIds: [1, 4],
      title: {
        en: "Experts | Aura Impact Platform",
        fr: "Experts | Plate-forme Aura Impact",
      },
    },
    component: ExpertsPage,
  },
  {
    path: "/expert/new",
    name: "ExpertAddPage",
    meta: {
      auth: true,
      allowUserIds: [1],
      title: {
        en: "Create Expert | Aura Impact Platform",
        fr: "Créer un expert | Plate-forme Aura Impact",
      },
    },
    component: ExpertAddPage,
  },
  {
    path: "/expert/edit/:expert",
    name: "ExpertEditPage",
    meta: {
      auth: true,
      allowUserIds: [1],
      title: {
        en: "Edit Expert | Aura Impact Platform",
        fr: "Edit Expert | Plate-forme Aura Impact",
      },
    },
    component: ExpertEditPage,
  },
  {
    path: "/expert/:expert",
    name: "ExpertSinglePage",
    meta: {
      auth: true,
      allowUserIds: [1, 4],
      title: {
        en: "Expert | Aura Impact Platform",
        fr: "Expert | Plate-forme Aura Impact",
      },
    },
    component: ExpertSinglePage,
  },
  {
    path: "/companies",
    name: "CompaniesPage",
    meta: {
      auth: true,
      allowUserIds: [1],
      title: {
        en: "Companies | Aura Impact Platform",
        fr: "Entreprises | Plate-forme Aura Impact",
      },
    },
    component: CompaniesPage,
  },
  {
    path: "/company/:company",
    name: "CompanyPage",
    meta: {
      auth: true,
      allowUserIds: [1],
      title: {
        en: "Company | Aura Impact Platform",
        fr: "Entreprise | Plate-forme Aura Impact",
      },
    },
    component: CompanyPage,
  },
  {
    path: "/company/new",
    name: "CompanyAddPage",
    meta: {
      auth: true,
      allowUserIds: [1],
      title: {
        en: "Create Company | Aura Impact Platform",
        fr: "Créer une entreprise | Plate-forme Aura Impact",
      },
    },
    component: CompanyAddPage,
  },
  {
    path: "/appointment/:appointment",
    name: "AppointmentPage",
    meta: {
      auth: true,
      allowUserIds: [2, 4],
      title: {
        en: "Appointment | Aura Impact Platform",
        fr: "Rendez-vous | Plate-forme Aura Impact",
      },
    },
    component: AppointmentPage,
  },
  {
    path: "/chats",
    name: "ChatPage",
    meta: {
      auth: true,
      allowUserIds: [2, 4],
      title: {
        en: "Chat | Aura Impact Platform",
        fr: "Chat | Plate-forme Aura Impact",
      },
    },
    component: ChatPage,
  },
  {
    path: "/calendar",
    name: "CalendarPage",
    meta: {
      auth: true,
      allowUserIds: [2, 4],
      title: {
        en: "Calendar | Aura Impact Platform",
        fr: "Calendrier | Plate-forme Aura Impact",
      },
    },
    component: CalendarPage,
  },
  {
    path: "/working-hours",
    name: "ExpertDayCalendarPage",
    meta: {
      auth: true,
      allowUserIds: [2],
      title: {
        en: "Day Calendar | Aura Impact Platform",
        fr: "Calendrier des jours | Plate-forme Aura Impact",
      },
    },
    component: ExpertDayCalendarPage,
  },
  {
    path: "/schedules",
    name: "ExpertSchedulesDayPage",
    meta: {
      auth: true,
      allowUserIds: [2],
      title: {
        en: "Schedules Day | Aura Impact Platform",
        fr: "Journée des horaires | Plate-forme Aura Impact",
      },
    },
    component: ExpertSchedulesDayPage,
  },
  {
    path: "/support",
    name: "SupportPage",
    meta: {
      auth: true,
      allowUserIds: [3],
      title: {
        en: "Support | Aura Impact Platform",
        fr: "Soutien | Plate-forme Aura Impact",
      },
    },
    component: SupportPage,
  },
  {
    path: "/account",
    name: "AccountPage",
    meta: {
      auth: true,
      allowUserIds: [1, 2, 3, 4],
      title: {
        en: "Account | Aura Impact Platform",
        fr: "Compte | Plate-forme Aura Impact",
      },
    },
    component: AccountPage,
  },
  {
    path: "/account/change-password",
    name: "ChangePasswordPage",
    meta: {
      auth: true,
      allowUserIds: [1, 2, 3, 4],
      title: {
        en: "Change Password | Aura Impact Platform",
        fr: "Changer le mot de passe | Plate-forme Aura Impact",
      },
    },
    component: ChangePasswordPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to, from, next) => {
  const requireAuth = to.matched.some((record) => record.meta.auth);
  if (!requireAuth) {
    next();
    return;
  }
  const userAuth = await isUserAuth();
  if (requireAuth && !userAuth) {
    localStorage.setItem("no_auth_path", to.fullPath);
    next("/login");
  } else {
    const userInformation = await axios.get("/auth/user");
    store.commit("SET_CURRENT_USER_INFORMATION", userInformation.data.body);
    if (
      userInformation.data.body.role_id === 4 ||
      userInformation.data.body.role_id === 2
    ) {
      axios.get("/appointments/upcoming").then(({ data }) => {
        store.commit("SET_UPCOMING_APPOINTMENT", data.body[0]);
        store.commit("SET_APPOINTMENTS", data.body);
      });
    }
    if (!to.meta.allowUserIds.includes(userInformation.data.body.role_id)) {
      next("/");
      return;
    }
    next();
  }
});

async function isUserAuth() {
  const userInformation = getCookie("access_token");
  return !!userInformation;
}

export default router;
