const calendar = {
  state: {
    day: new Date(new Date().setDate(new Date().getDate() + 1)),
    // day: new Date(),
    showCalendar: false,
    dayOptions: [],
    dayInterval: [],
    reschedule: null,
  },
  mutations: {
    SET_CALENDAR_DAY: (state, payload) => {
      state.day = payload;
    },
    SET_SHOW_CALENDAR: (state, payload) => {
      state.showCalendar = payload;
    },
    SET_DAY_OPTIONS: (state, payload) => {
      state.dayOptions = payload;
    },
    SET_DAY_INTERVAL: (state, payload) => {
      state.dayInterval = payload;
    },
    SET_RESCHEDULE: (state, payload) => {
      state.reschedule = payload;
    },
  },
  actions: {},
  modules: {},
  getters: {
    CALENDAR_DAY: (state) => {
      return state.day;
    },
    SHOW_CALENDAR: (state) => {
      return state.showCalendar;
    },
    DAY_OPTIONS: (state) => {
      return state.dayOptions;
    },
    DAY_INTERVAL: (state) => {
      return state.dayInterval;
    },
    RESCHEDULE: (state) => {
      return state.reschedule;
    },
  },
};

export default calendar;
