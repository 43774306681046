const notifications = {
  state: {
    haveNotifications: false,
    openChat: null,
    notReadMessage: [],
    chats: [],
    chatMessages: [],
  },
  mutations: {
    SET_NOTIFICATIONS: (state, payload) => {
      state.haveNotifications = payload;
    },
    SET_NOT_READ_MESSAGES: (state, payload) => {
      state.notReadMessage = payload;
    },
    SET_OPEN_CHAT: (state, payload) => {
      state.openChat = payload;
    },
    SET_CHATS: (state, payload) => {
      state.chats = payload;
    },
    SET_CHAT_MESSAGES: (state, payload) => {
      state.chatMessages = payload;
    },
  },
  actions: {},
  modules: {},
  getters: {
    NOTIFICATIONS: (state) => {
      return state.haveNotifications;
    },
    NOT_READ_MESSAGES: (state) => {
      return state.notReadMessage;
    },
    OPEN_CHAT: (state) => {
      return state.openChat;
    },
    CHATS: (state) => {
      return state.chats;
    },
    CHAT_MESSAGES: (state) => {
      return state.chatMessages;
    },
  },
};

export default notifications;
