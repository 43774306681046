const states = {
  state: {
    employeesUpdate: null,
    loadedState: false,
    rescheduleAppointmentPopup: {
      show: false,
      to: "",
      save: null,
      leave: null,
    },
    deleteEmployee: {
      show: false,
      email: null,
      user_id: null,
      role_id: 4,
    },
    havePayment: null,
    rebook: null,
    showChat: false,
    cancelAppointment: false,
    changePasswordPopup: false,
    addNewTag: false,
    applyingCredits: false,
    changePasswordLeavePopup: {
      show: false,
      to: null,
      save: null,
    },
  },
  mutations: {
    SET_DELETE_EMPLOYEE_POPUP: (state, payload) => {
      state.deleteEmployee = payload;
    },
    SET_LOADED_STATE: (state, payload) => {
      state.loadedState = payload;
    },
    SET_SHOW_CHAT: (state, payload) => {
      state.showChat = payload;
    },
    SET_RESCHEDULE_APPOINTMENT_POPUP: (state, payload) => {
      state.rescheduleAppointmentPopup = payload;
    },
    SET_PAYMENT: (state, payload) => {
      state.havePayment = payload;
    },
    SET_REBOOK: (state, payload) => {
      state.rebook = payload;
    },
    SET_ADD_NEW_TAG: (state, payload) => {
      state.addNewTag = payload;
    },
    SET_APPLYING_CREDITS: (state, payload) => {
      state.applyingCredits = payload;
    },
    SET_CANCEL_APPOINTMENT: (state, payload) => {
      state.cancelAppointment = payload;
    },
    SET_CHANGE_EMPLOYEE_PASSWORD_POPUP: (state, payload) => {
      state.changePasswordPopup = payload;
    },
    SET_CHANGE_EMPLOYEE_PASSWORD_LEAVE_POPUP: (state, payload) => {
      state.changePasswordLeavePopup = payload;
    },
  },
  actions: {},
  modules: {},
  getters: {
    LOADED_STATE: (state) => {
      return state.loadedState;
    },
    DELETE_EMPLOYEE_POPUP: (state) => {
      return state.deleteEmployee;
    },
    SHOW_CHAT: (state) => {
      return state.showChat;
    },
    RESCHEDULE_APPOINTMENT_POPUP: (state) => {
      return state.rescheduleAppointmentPopup;
    },
    PAYMENT: (state) => {
      return state.havePayment;
    },
    REBOOK: (state) => {
      return state.rebook;
    },
    ADD_NEW_TAG: (state) => {
      return state.addNewTag;
    },
    APPLYING_CREDITS: (state) => {
      return state.applyingCredits;
    },
    CANCEL_APPOINTMENT: (state) => {
      return state.cancelAppointment;
    },
    CHANGE_EMPLOYEE_PASSWORD_POPU: (state) => {
      return state.changePasswordPopup;
    },
    CHANGE_EMPLOYEE_PASSWORD_LEAVE_POPUP: (state) => {
      return state.changePasswordLeavePopup;
    },
  },
};

export default states;
