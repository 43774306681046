import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import axiosNoAuth from "axios";
import VueAxios from "vue-axios";
import router from "./router/router";
import store from "./store/store";
import useVuelidate from "@vuelidate/core";
import Toast from "vue-toastification";
import CKEditor from "@ckeditor/ckeditor5-vue";
import DropZone from "dropzone-vue";
import Maska from "maska";
import { createI18n } from "vue-i18n/index";
import en from "../src/languages/en.json";
import Datepicker from "@vuepic/vue-datepicker";
import VueSocketIO from "vue-3-socket.io";
import SocketIO from "socket.io-client";
import { setAxiosConfig } from "@/axiosConfig";
import "@popperjs/core";
import "@vuepic/vue-datepicker/dist/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "normalize.css";
import "dropzone-vue/dist/dropzone-vue.common.css";
import "../node_modules/vue-multiselect/dist/vue-multiselect.css";
import "animate.css";
import "vue-toastification/dist/index.css";
import "@/styles/main.sass";

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "fr",
  messages: { en },
});
const toastOpt = {
  position: "top-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
  transition: "Vue-Toastification__fade",
};
createApp(App)
  .use(DropZone)
  .use(store)
  .use(router)
  .use(CKEditor)
  .use(Toast, toastOpt)
  .use(i18n)
  .use(VueAxios, {
    $http: axios,
    $noAuth: axiosNoAuth,
  })
  .use(useVuelidate)
  .use(Maska)
  .component("Datepicker", Datepicker)
  .use(
    new VueSocketIO({
      connection: SocketIO(process.env.VUE_APP_ORIGIN_SOCKET_PATH),
    })
  )
  .mount("#app");
setAxiosConfig();
